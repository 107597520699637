import * as React from "react";
import type { RangeDetails } from "../util/rangesAPI";

const cards = ["A", "K", "Q", "J", "T", "9", "8", "7", "6", "5", "4", "3", "2"];

const eachCombo = (fn: (card: string) => React.ReactNode) =>
  cards.map((card1, card1Value) =>
    cards.map((card2, card2Value) => {
      if (card1Value < card2Value) {
        return fn(`${card1}${card2}o`);
      } else if (card1Value > card2Value) {
        return fn(`${card2}${card1}s`);
      } else {
        return fn(`${card1}${card2}`);
      }
    })
  );

const Card = (props: { card: React.ReactNode }) => (
  <div className="flex place-content-center place-items-center h-6 w-6 md:h-7 md:w-7 lg:h-9 lg:w-9 text-tiny lg:text-xs">
    {props.card}
  </div>
);

const getColors = (card: string, range?: RangeDetails | null) => {
  if (!range) {
    return "bg-gray-300 text-white";
  } else if (range.raise.includes(card)) {
    return "bg-red-500 text-white";
  } else if (range.call.includes(card)) {
    return "bg-green-400 text-white";
  } else if (range.fold.includes(card)) {
    return "bg-gray-300 text-white";
  } else if (range.raiseFold.includes(card)) {
    return "bg-red-300 text-black";
  } else if (range.raiseCall.includes(card)) {
    return "bg-yellow-500 text-white";
  } else {
    return "bg-gray-300 text-white";
  }
};

const Action = ({
  card,
  range,
  onClick,
}: {
  card: string;
  range?: RangeDetails | null;
  onClick: (card: string) => void;
}) => {
  return (
    <button
      className={`transition duration-1000 ${getColors(card, range)}`}
      onClick={() => onClick(card)}
    >
      <Card card={card} />
    </button>
  );
};

const captionText = (range: RangeDetails | null) => {
  if (!range) {
    return "N/A";
  } else if (range.situation === "rfi") {
    return `RFI from ${range.heroPosition}`;
  } else if (range.situation === "rfiVs3Bet") {
    return `${range.heroPosition} vs 3-Bet from ${range.villainPosition}`;
  } else if (range.situation === "vsRfi") {
    return `${range.heroPosition} vs RFI from ${range.villainPosition}`;
  }
};

const Caption = ({ range = null }: { range?: RangeDetails | null }) => (
  <div className="text-center text-xs md:text-sm pt-1 md:pt-2">
    {captionText(range)}
  </div>
);

export default function Range({
  range,
  onClick,
}: {
  range?: RangeDetails | null;
  onClick: (card: string) => void;
}) {
  return (
    <div className="flex items-center flex-col">
      <div className="rounded-lg overflow-hidden">
        <div className="grid grid-flow-col grid-cols-13 grid-rows-13">
          {eachCombo((card) => (
            <Action key={card} range={range} card={card} onClick={onClick} />
          ))}
        </div>
      </div>
      <Caption range={range} />
    </div>
  );
}
