import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from ".";
import { fetchRanges } from "../util/rangesAPI";
import type { RangeDetails } from "../util/rangesAPI";

const noDetails: RangeDetails[] = [];
const initialState: {
  live: RangeDetails[];
  online: RangeDetails[];
  mode: "live" | "online";
} = {
  live: noDetails,
  online: noDetails,
  mode: "online",
};

export const loadRanges = createAsyncThunk("ranges/loadRanges", async () => {
  return {
    live: await fetchRanges("/upswingLive.prt"),
    online: await fetchRanges("/upswingOnline.prt"),
  };
});

export const rangesSlice = createSlice({
  name: "ranges",
  initialState,
  reducers: {
    setRangeMode: (state, action: PayloadAction<"live" | "online">) => {
      state.mode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadRanges.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});

export const { setRangeMode } = rangesSlice.actions;

export const selectRangesSlice = (state: RootState) => state.ranges,
  selectRangeMode = createSelector(selectRangesSlice, (ranges) => ranges.mode),
  selectRanges = createSelector(
    selectRangesSlice,
    selectRangeMode,
    (ranges, mode) => ranges[mode]
  );

export default rangesSlice.reducer;
