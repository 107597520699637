import Ranges from "./components/Ranges";
import { useAppDispatch } from "./app/hooks";
import { loadRanges } from "./store/rangesSlice";
import { useEffect } from "react";
import { Table } from "./components/Table";

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadRanges());
  }, [dispatch]);
  return (
    <div className="flex h-full place-content-center">
      <div className="w-full md:w-100">
        <Table />
        <Ranges />
      </div>
    </div>
  );
}

export default App;
