import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Position, positions } from "../util/rangesAPI";
import { selectRangeMode, setRangeMode } from "../store/rangesSlice";
import {
  selectTable,
  setHeroPosition,
  setTableSize,
  setVillainPosition,
  TableSize,
} from "../store/tableSlice";
import { useState } from "react";

const positionXY = {
  6: [
    "top-[100%] left-[50%]",
    "top-[65%] left-0",
    "top-[20%] left-[5%]",
    "top-0 left-[50%]",
    "top-[20%] left-[95%]",
    "top-[65%] left-[100%]",
  ],
  9: [
    "top-[100%] left-[50%]",
    "top-[100%] left-[20%]",
    "top-[65%] left-0",
    "top-[20%] left-[5%]",
    "top-0 left-[35%]",
    "top-0 left-[65%]",
    "top-[20%] left-[95%]",
    "top-[65%] left-[100%]",
    "top-[100%] left-[80%]",
  ],
};

const chipsXY = {
  6: [
    "top-[83%] left-[50%]",
    "top-[65%] left-[10%]",
    "top-[20%] left-[15%]",
    "top-[17%] left-[50%]",
    "top-[20%] left-[85%]",
    "top-[65%] left-[90%]",
  ],
  9: [
    "top-[83%] left-[50%]",
    "top-[83%] left-[20%]",
    "top-[65%] left-[10%]",
    "top-[20%] left-[15%]",
    "top-[17%] left-[35%]",
    "top-[17%] left-[65%]",
    "top-[20%] left-[85%]",
    "top-[65%] left-[90%]",
    "top-[83%] left-[80%]",
  ],
};

const recenter = (position: Position, tableSize: TableSize): Position[] => {
  const truncatedPositions = positions.slice(9 - tableSize);
  const index = truncatedPositions.indexOf(position);
  return [
    ...truncatedPositions.slice(index),
    ...truncatedPositions.slice(0, index),
  ];
};

function getCardColor(
  position: Position,
  heroPosition: Position,
  villainPosition: Position | null
) {
  if (position === heroPosition) {
    return "bg-white border-black";
  } else if (position === villainPosition) {
    return "bg-black border-white text-white";
  } else {
    return "bg-gray-200 border-gray-400 text-gray-400";
  }
}

function LiveOnlineSelector() {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectRangeMode);

  return (
    <div className="absolute top-1/2 left-1/2 text-tiny md:text-sm -mt-6 h-4 w-24 -ml-12 md:-ml-24 md:-mt-14 md:w-48 md:h-8 flex flex-auto gap-2 ">
      <button
        className={`flex-1 rounded border ${
          mode === "online"
            ? "bg-white border-black"
            : "bg-gray-200 text-gray-400"
        }`}
        onClick={() => {
          dispatch(setRangeMode("online"));
        }}
      >
        Online
      </button>
      <button
        className={`flex-1 rounded border ${
          mode === "live"
            ? "bg-white border-black"
            : "bg-gray-200 text-gray-400 border-gray-400"
        }`}
        onClick={() => {
          dispatch(setRangeMode("live"));
        }}
      >
        Live
      </button>
    </div>
  );
}

function TableSizeSelector() {
  const dispatch = useAppDispatch();
  const { heroPosition, tableSize } = useAppSelector(selectTable);

  return (
    <div className="absolute top-1/2 left-1/2 text-tiny md:text-sm h-4 w-24 -ml-12 -mt-0 md:-ml-24 md:-mt-4 md:w-48 md:h-8 flex flex-auto gap-2 ">
      <button
        className={`flex-1 rounded border ${
          tableSize === 6
            ? "bg-white border-black"
            : "bg-gray-200 text-gray-400 border-gray-400"
        }`}
        onClick={() => {
          dispatch(setTableSize(6));
          const newHeroPosition = recenter(heroPosition, 6)[0];
          dispatch(setHeroPosition(newHeroPosition));
        }}
      >
        6-max
      </button>
      <button
        className={`flex-1 rounded border ${
          tableSize === 9
            ? "bg-white border-black"
            : "bg-gray-200 text-gray-400"
        }`}
        onClick={() => {
          dispatch(setTableSize(9));
        }}
      >
        9-max
      </button>
    </div>
  );
}

function TablePosition({
  position,
  positionIndex,
}: {
  position: Position;
  positionIndex: number;
}) {
  const [lastClick, setLastClick] = useState(0);
  const dispatch = useAppDispatch();
  const { heroPosition, villainPosition, tableSize } =
    useAppSelector(selectTable);

  const cardColor = getCardColor(position, heroPosition, villainPosition);
  const positionClasses = positionXY[tableSize][positionIndex];
  const chipsClasses = chipsXY[tableSize][positionIndex];

  return (
    <>
      <button
        key={`card${position}`}
        className={`${cardColor} ${positionClasses} flex place-items-center place-content-center rounded-lg font-bold border-2 border-solid absolute text-tiny w-10 h-8 -ml-5 -mt-4 md:text-sm md:w-16 md:h-10 md:-ml-8 md:-mt-5 lg:text-base lg:w-24 lg:h-16 lg:-ml-12 lg:-mt-8`}
        onClick={(e) => {
          console.log(e);
          const now = new Date().valueOf();
          if (now - lastClick < 250) {
            dispatch(setHeroPosition(position));
          } else {
            dispatch(setVillainPosition(position));
          }
          setLastClick(now);
        }}
        disabled={position === heroPosition}
      >
        {position}
      </button>
      {position === "BTN" && (
        <div
          key="button"
          className={`flex place-items-center place-content-center w-4 h-4 md:w-6 md:h-6 rounded-full text-tiny md:text-xs bg-red-500 text-white -m-3 font-bold absolute ${chipsClasses}`}
        >
          D
        </div>
      )}
      {position === "SB" && (
        <div
          key="small"
          className={`flex place-items-center place-content-center w-2 h-2 md:w-4 md:h-4 rounded-full text-xs bg-red-800 text-white font-bold border-solid border-black border absolute ${chipsClasses}`}
        ></div>
      )}
      {position === "BB" && (
        <div key="big">
          <div
            className={`flex place-items-center place-content-center w-2 h-2 md:w-4 md:h-4 rounded-full text-xs bg-red-800 text-white font-bold border-solid border-black border absolute ${chipsClasses}`}
          ></div>
          <div
            className={`flex place-items-center place-content-center w-2 h-2 md:w-4 md:h-4 -m-1 rounded-full text-xs bg-red-800 text-white font-bold border-solid border-black border absolute ${chipsClasses}`}
          ></div>
        </div>
      )}
    </>
  );
}

export function Table() {
  const dispatch = useAppDispatch();
  const { tableSize, heroPosition } = useAppSelector(selectTable);

  const centeredPositions = recenter(heroPosition, tableSize);
  return (
    <div className="flex flex-col items-center mx-8">
      <div className="w-full md:w-5/6 h-[30vw] m-12 md:max-h-96 bg-green-700 rounded-[30vw] relative">
        <LiveOnlineSelector />
        <TableSizeSelector />
        {centeredPositions.map((position, positionIndex) => (
          <TablePosition
            key={position}
            position={position}
            positionIndex={positionIndex}
          />
        ))}
        <button
          className="button absolute bg-white border border-black rounded top-[100%] left-[38%] md:left-[40%] text-tiny w-6 h-6 -ml-3 -mt-3 md:text-small md:w-8 md:h-8 md:-ml-4 md:-mt-4 lg:text-base lg:w-10 lg:h-10 lg:-ml-5 lg:-mt-5"
          onClick={() => dispatch(setHeroPosition(centeredPositions[1]))}
        >
          &lt;
        </button>
        <button
          className="button absolute bg-white border border-black rounded top-[100%] left-[62%] md:left-[60%] text-tiny w-6 h-6 -ml-3 -mt-3 md:text-small md:w-8 md:h-8 md:-ml-4 md:-mt-4 lg:text-base lg:w-10 lg:h-10 lg:-ml-5 lg:-mt-5"
          onClick={() =>
            dispatch(
              setHeroPosition(centeredPositions[centeredPositions.length - 1])
            )
          }
        >
          &gt;
        </button>
      </div>
    </div>
  );
}
