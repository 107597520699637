import { createSelector } from "@reduxjs/toolkit";
import { Position, RangeDetails, Situation } from "../util/rangesAPI";
import { selectRanges } from "./rangesSlice";
import {
  IpAction,
  OopAction,
  PlayStyle,
  selectHeroLastAction,
  selectHeroSituation,
  selectTable,
  selectVillainLastAction,
  selectVillainSituation,
} from "./tableSlice";

const emptyRange: string[] = [];

const findBaseRange = (
  ranges: RangeDetails[],
  heroPosition: Position | null,
  villainPosition: Position | null,
  situation: Situation
) =>
  ranges.find(
    (range) =>
      range.heroPosition === heroPosition &&
      range.villainPosition ===
        (situation === "rfi" ? null : villainPosition) &&
      range.situation === situation
  );

const filterRange = (
  baseRange: RangeDetails,
  lastAction: IpAction | OopAction,
  style: PlayStyle
) => {
  if (!baseRange) {
    return null;
  } else if (lastAction === "3Bet" || lastAction === "4Bet") {
    return {
      ...baseRange,
      call: emptyRange,
      fold: emptyRange,
      raiseCall: style.aggression === "call" ? emptyRange : baseRange.raiseCall,
      raiseFold: style.tightness === "fold" ? emptyRange : baseRange.raiseFold,
    };
  } else if (lastAction === "call" || lastAction === "call3Bet") {
    return {
      ...baseRange,
      raise: emptyRange,
      raiseFold: emptyRange,
      raiseCall:
        style.aggression === "raise" ? emptyRange : baseRange.raiseCall,
    };
  } else {
    return baseRange;
  }
};

export const selectHeroRange = createSelector(
    selectRanges,
    selectHeroSituation,
    selectTable,
    (ranges, heroSituation, { heroPosition, villainPosition }) =>
      findBaseRange(ranges, heroPosition, villainPosition, heroSituation)
  ),
  selectVillainRange = createSelector(
    selectRanges,
    selectVillainSituation,
    selectTable,
    (ranges, villainSituation, { heroPosition, villainPosition }) =>
      findBaseRange(ranges, villainPosition, heroPosition, villainSituation)
  ),
  selectFilteredHeroRange = createSelector(
    selectHeroRange,
    selectHeroLastAction,
    selectTable,
    (range, lastAction, { heroStyle, villainStyle }) =>
      range && filterRange(range, lastAction, heroStyle)
  ),
  selectFilteredVillainRange = createSelector(
    selectVillainRange,
    selectVillainLastAction,
    selectTable,
    (range, lastAction, { heroStyle, villainStyle }) =>
      range && filterRange(range, lastAction, villainStyle)
  );
