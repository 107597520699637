import prange from "prange";

export type Position =
  | "UTG"
  | "UTG+1"
  | "UTG+2"
  | "LJ"
  | "HJ"
  | "CO"
  | "BTN"
  | "SB"
  | "BB";

export type Situation = "rfi" | "vsRfi" | "rfiVs3Bet";
export const situations: Situation[] = ["rfi", "vsRfi", "rfiVs3Bet"];

export const groups = {
  raise: "Group 1",
  call: "Group 2",
  fold: "Group 3",
  raiseFold: "Group 13",
  raiseCall: "Group 16",
};

export const positions: Position[] = [
  "UTG",
  "UTG+1",
  "UTG+2",
  "LJ",
  "HJ",
  "CO",
  "BTN",
  "SB",
  "BB",
];

export interface RangeDetails {
  heroPosition: Position;
  villainPosition: Position | null;
  situation: Situation;
  raise: string[];
  call: string[];
  fold: string[];
  raiseFold: string[];
  raiseCall: string[];
}

const parseRange = (root: Element | null) => {
  const rangeText = root?.attributes.getNamedItem("text");
  return rangeText?.value ? prange(rangeText.value) : [];
};

const getRangeNode = (root: Element | null, ...names: string[]) =>
  root
    ? root.querySelector(names.map((name) => `[name="${name}"]`).join(" "))
    : null;

const getGroups = (root: Element | null) => ({
  raise: parseRange(getRangeNode(root, "Group 1")),
  call: parseRange(getRangeNode(root, "Group 2")),
  fold: parseRange(getRangeNode(root, "Group 3")),
  raiseFold: parseRange(getRangeNode(root, "Group 13")),
  raiseCall: parseRange(getRangeNode(root, "Group 16")),
});

const rfi = (root: Element | null, heroPosition: string) =>
  getGroups(getRangeNode(root, "RFI", heroPosition));
const vsRfi = (
  root: Element | null,
  heroPosition: string,
  villainPosition: string
) => getGroups(getRangeNode(root, "vs RFI", villainPosition, heroPosition));

const rfiVs3Bet = (
  root: Element | null,
  heroPosition: string,
  villainPosition: string
) =>
  getGroups(getRangeNode(root, "RFI vs 3-Bet", heroPosition, villainPosition));

const allRfi = (root: Element | null): RangeDetails[] =>
  positions.map((heroPosition) => ({
    situation: "rfi",
    heroPosition,
    villainPosition: null,
    ...rfi(root, heroPosition),
  }));

const allVsRfi = (root: Element | null): RangeDetails[] =>
  positions.flatMap((heroPosition) =>
    positions.map((villainPosition) => ({
      situation: "vsRfi",
      heroPosition,
      villainPosition,
      ...vsRfi(root, heroPosition, villainPosition),
    }))
  );

const allRfiVs3Bet = (root: Element | null): RangeDetails[] =>
  positions.flatMap((heroPosition) =>
    positions.map((villainPosition) => ({
      situation: "rfiVs3Bet",
      heroPosition,
      villainPosition,
      ...rfiVs3Bet(root, heroPosition, villainPosition),
    }))
  );

export async function fetchRanges(file: string) {
  const result = await fetch(file);
  const text = await result.text();
  const parser = new DOMParser();
  const xml = parser.parseFromString(text, "application/xml");
  const root = xml.firstElementChild;
  const allRanges = [...allRfi(root), ...allVsRfi(root), ...allRfiVs3Bet(root)];
  return allRanges;
}
