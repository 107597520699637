import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { positions } from "../util/rangesAPI";
import type { Position, Situation } from "../util/rangesAPI";

export type OopAction = "rfi" | "call3Bet" | "4Bet";
export type IpAction = "allOptions" | "call" | "3Bet";
export type TableSize = 6 | 9;
export type Aggresssion = "raiseCall" | "raise" | "call";
export type Tightness = "raiseFold" | "raise" | "fold";

export const oopActions: OopAction[] = ["rfi", "call3Bet", "4Bet"];
export const ipActions: IpAction[] = ["allOptions", "call", "3Bet"];

export interface PlayStyle {
  aggression: Aggresssion;
  tightness: Tightness;
}

export interface TableState {
  heroPosition: Position;
  villainPosition: Position | null;
  heroStyle: PlayStyle;
  villainStyle: PlayStyle;
  oopAction: OopAction;
  ipAction: IpAction;
  tableSize: TableSize;
}

const initialState: TableState = {
  heroPosition: "BTN",
  villainPosition: "CO",
  heroStyle: {
    aggression: "raise",
    tightness: "raise",
  },
  villainStyle: {
    aggression: "raiseCall",
    tightness: "raiseFold",
  },
  oopAction: "rfi",
  ipAction: "allOptions",
  tableSize: 6,
};

const swapPosition = (state: TableState) => {
  if (state.villainPosition) {
    [state.heroPosition, state.villainPosition] = [
      state.villainPosition,
      state.heroPosition,
    ];
  }
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setHeroPosition: (state, action: PayloadAction<Position>) => {
      state.heroPosition = action.payload;
      state.villainPosition = null;
      state.oopAction = "rfi";
      state.ipAction = "allOptions";
    },
    setVillainPosition: (state, action: PayloadAction<Position | null>) => {
      if (state.villainPosition === action.payload) {
        state.villainPosition = null;
      } else if (state.heroPosition === action.payload) {
        swapPosition(state);
      } else {
        state.villainPosition = action.payload;
      }
      state.ipAction = "allOptions";
    },
    setHeroRaiseStyle: (state, action: PayloadAction<Aggresssion>) => {
      state.heroStyle.aggression = action.payload;
    },
    setVillainRaiseStyle: (state, action: PayloadAction<Aggresssion>) => {
      state.villainStyle.aggression = action.payload;
    },
    setHeroCallStyle: (state, action: PayloadAction<Tightness>) => {
      state.heroStyle.tightness = action.payload;
    },
    setVillainCallStyle: (state, action: PayloadAction<Tightness>) => {
      state.villainStyle.tightness = action.payload;
    },
    setIpAction: (state, action: PayloadAction<IpAction>) => {
      state.ipAction = action.payload;
    },
    setOopAction: (state, action: PayloadAction<OopAction>) => {
      state.oopAction = action.payload;
    },
    setTableSize: (state, action: PayloadAction<TableSize>) => {
      state.tableSize = action.payload;
    },
  },
});

export const {
  setHeroPosition,
  setVillainPosition,
  setIpAction,
  setOopAction,
  setTableSize,
} = tableSlice.actions;

export const selectTable = (state: RootState) => state.table;

const getSituation = (isOop: boolean, ipAction: IpAction): Situation => {
  if (isOop && ipAction === "3Bet") {
    return "rfiVs3Bet";
  } else if (isOop) {
    return "rfi";
  } else {
    return "vsRfi";
  }
};

export const selectIsHeroOop = createSelector(
    selectTable,
    ({ heroPosition, villainPosition }) =>
      !villainPosition ||
      positions.indexOf(heroPosition) < positions.indexOf(villainPosition)
  ),
  selectIsVillainOop = createSelector(
    selectIsHeroOop,
    (isHeroOop) => !isHeroOop
  ),
  selectHeroLastAction = createSelector(
    selectIsHeroOop,
    selectTable,
    (isHeroOop, { oopAction, ipAction }) => (isHeroOop ? oopAction : ipAction)
  ),
  selectVillainLastAction = createSelector(
    selectIsVillainOop,
    selectTable,
    (isVillainOop, { oopAction, ipAction }) =>
      isVillainOop ? oopAction : ipAction
  ),
  selectHeroSituation = createSelector(
    selectIsHeroOop,
    selectTable,
    (isHeroOop, { ipAction }) => getSituation(isHeroOop, ipAction)
  ),
  selectVillainSituation = createSelector(
    selectIsVillainOop,
    selectTable,
    (isVillainOop, { ipAction }) => getSituation(isVillainOop, ipAction)
  );

export default tableSlice.reducer;
