import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RangeDetails } from "../util/rangesAPI";
import {
  selectIsHeroOop,
  selectTable,
  setIpAction,
  setOopAction,
} from "../store/tableSlice";
import {
  selectFilteredHeroRange,
  selectFilteredVillainRange,
} from "../store/uiSelectors";
import Range from "./Range";
import { calculateEquity } from "../util/equityCalculator";

function ActionButton({
  selected,
  onClick,
  children,
  disabled = false,
}: {
  selected: boolean;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}) {
  return (
    <button
      disabled={disabled}
      className={`flex-1 rounded border border-black ${
        !disabled && selected
          ? "bg-white  border-black"
          : "bg-gray-200 text-gray-400 border-gray-400"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function OopActionButtons() {
  const dispatch = useAppDispatch();
  const { ipAction, oopAction } = useAppSelector(selectTable);

  return (
    <div className="w-full flex flex-auto gap-2 h-8">
      <ActionButton
        selected={oopAction === "rfi"}
        onClick={() => dispatch(setOopAction("rfi"))}
      >
        {ipAction === "3Bet" ? "All Options" : "RFI"}
      </ActionButton>
      <ActionButton
        selected={oopAction === "call3Bet"}
        onClick={() => {
          dispatch(setIpAction("3Bet"));
          dispatch(setOopAction("call3Bet"));
        }}
      >
        Call 3-Bet
      </ActionButton>
      <ActionButton
        selected={oopAction === "4Bet"}
        onClick={() => {
          dispatch(setIpAction("3Bet"));
          dispatch(setOopAction("4Bet"));
        }}
      >
        4-Bet
      </ActionButton>
    </div>
  );
}

function IpActionButtons({ disabled }: { disabled?: boolean }) {
  const dispatch = useAppDispatch();
  const { ipAction } = useAppSelector(selectTable);

  return (
    <div className="w-full flex flex-auto gap-2 h-8">
      <ActionButton
        selected={ipAction === "allOptions"}
        onClick={() => {
          dispatch(setIpAction("allOptions"));
          dispatch(setOopAction("rfi"));
        }}
      >
        All Options
      </ActionButton>
      <ActionButton
        selected={ipAction === "call"}
        onClick={() => {
          dispatch(setIpAction("call"));
          dispatch(setOopAction("rfi"));
        }}
      >
        Call RFI
      </ActionButton>
      <ActionButton
        selected={ipAction === "3Bet"}
        onClick={() => dispatch(setIpAction("3Bet"))}
      >
        3-Bet
      </ActionButton>
    </div>
  );
}

async function displayEquity(card: string, opposingRange: RangeDetails) {
  const { equity, rangeString } = await calculateEquity(card, opposingRange);
  const message = `${card} has ${Math.round(equity)}% equity vs range`;
  prompt(message, rangeString);
}

export default function Ranges() {
  const heroRange = useAppSelector(selectFilteredHeroRange);
  const villainRange = useAppSelector(selectFilteredVillainRange);
  const isHeroOop = useAppSelector(selectIsHeroOop);

  return (
    <div className="flex flex-col gap-2 items-center">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="flex flex-col gap-4">
          {isHeroOop ? (
            <OopActionButtons />
          ) : (
            <IpActionButtons disabled={false} />
          )}
          <Range
            range={heroRange}
            onClick={(card) =>
              villainRange && displayEquity(card, villainRange)
            }
          />
        </div>
        <div className="flex flex-col gap-4">
          {!isHeroOop ? (
            <OopActionButtons />
          ) : (
            <IpActionButtons disabled={!villainRange} />
          )}
          <Range
            range={villainRange}
            onClick={(card) => heroRange && displayEquity(card, heroRange)}
          />
        </div>
      </div>
    </div>
  );
}
