import "requestidlecallback-polyfill";
import { RangeDetails } from "./rangesAPI";
import { Combination, Permutation } from "js-combinatorics";
import zip from "lodash/zip";
import { raceRange, rates } from "pec";

function getSuitSetForSummary(suitSummary: string) {
  const suits = ["s", "h", "d", "c"];
  if (suitSummary === "s") {
    return suits.map((suit) => [suit, suit]);
  } else if (suitSummary === "o") {
    return [...new Permutation(suits, 2)].filter(
      ([suit1, suit2]) => suit1 !== suit2
    );
  } else {
    return [...new Combination(suits, 2)].filter(
      ([suit1, suit2]) => suit1 !== suit2
    );
  }
}

function rangeDoubletToHands(doublet: string) {
  const [card1, card2, suitSummary] = doublet;
  const suitSet = getSuitSetForSummary(suitSummary);
  return suitSet.map((suits) =>
    zip([card1, card2], suits).map((a) => a.join(""))
  );
}

function mergeRange(range: RangeDetails) {
  return [
    ...range.call,
    ...range.raise,
    ...range.raiseCall,
    ...range.raiseFold,
  ];
}

export function calculateEquity(doublet: string, opposingRange: RangeDetails) {
  const sampleHand = rangeDoubletToHands(doublet)[0];
  const mergedRange = mergeRange(opposingRange);
  const cards = mergedRange.flatMap((doublet) => rangeDoubletToHands(doublet));
  const rangeString = mergedRange.join(", ");
  return new Promise<{ equity: number; winRate: number; rangeString: string }>(
    (resolve) => {
      window.requestIdleCallback(() => {
        const { win, loose, tie } = raceRange(sampleHand, cards, 500);
        const { winRate, tieRate } = rates({ win, loose, tie });
        const equity = winRate + tieRate / 2;
        resolve({ equity, winRate, rangeString });
      });
    }
  );
}
